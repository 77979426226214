<template>
	<div class="flex" style="flex-direction: column; height: 100%">
		<sm-card title="筛选查询" noHeader icon="el-icon-search" style="height: 80px">
			<el-form slot="body" class="flex" inline ref="form" :model="searchParm" label-width="120px">
				<el-row class="flex-1">
					<el-col :span="60">
						<el-form-item label="设备名称：" prop="name">
							<el-cascader v-model="searchParm.unitId" :options="treeData" :props="{
									value: 'id',
									label: 'label',
									children: 'children',
									emitPath: false
								}"></el-cascader>
						</el-form-item>
					</el-col>

					<el-col :span="60">
						<el-form-item label="类型：">
							<el-select v-model="searchParm.type" collapse-tags clearable :popper-append-to-body="false"
								placeholder="请选择" @change="list()">
								<el-option v-for="item in knowledgeTypeData" :key="item.id" :label="item.name"
									:value="item.id"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="60">
						<el-input v-model="searchParm.keyword" style="min-width: 300px;" placeholder="设备区域/设备名称/文档名称">
							<i class="el-input__icon el-icon-search" slot="suffix"></i>
						</el-input>
					</el-col>
				</el-row>
				<div>
					<el-button type="primary" @click="list()">查询</el-button>
					<el-button plain @click="clearSearch()">重置</el-button>
				</div>
			</el-form>
		</sm-card>
		<div class="tableModule">
			<div class="title">
				<div class="title-left">
					<i class="el-icon-s-operation"></i>
					<span>数据列表</span>
				</div>
				<div class="title-right"><el-button v-hasPerm="'1554309034776072192'" type="primary" size="medium"
						@click="uploadDialog()" style="margin-right: 8px">上传文件</el-button></div>
			</div>
			<div class="tableContainer">
				<div class="table" ref="tableContainer">
					<div>
						<el-table ref="usertable" :data="tableData.records" border :height="tableHeight"
							:header-cell-style="{ 'background-color': '#f9fafc' }" :cell-style="{ padding: 0 }"
							style="margin-bottom: 20px" v-loading="isLoading">
							<el-table-column align="center" label="序号" show-overflow-tooltip>
								<template slot-scope="scope">
									<div>{{ scope.$index + 1 }}</div>
								</template>
							</el-table-column>
							<el-table-column prop="parentName" align="center" label="设备区域"
								show-overflow-tooltip></el-table-column>
							<el-table-column prop="unitName" align="center" label="设备名称"
								show-overflow-tooltip></el-table-column>
							<el-table-column width="150" prop="fileName" align="center" label="文档名称"
								show-overflow-tooltip></el-table-column>
							<el-table-column v-if="rolePermissions" prop="entName" align="center" label="发布企业"
								show-overflow-tooltip></el-table-column>
							<el-table-column prop="type" align="center" label="类型" show-overflow-tooltip
								:formatter="typeFormatter"></el-table-column>
							<el-table-column prop="userName" align="center" label="上传人员"
								show-overflow-tooltip></el-table-column>

							<el-table-column prop="modify_time" align="center" label="发布时间" show-overflow-tooltip
								:formatter="
									(row, column) => {
										let data = row.modify_time;

										if (data === '') {
											return '';
										} else {
											let dt = new Date(data);
											return (
												dt.getFullYear() +
												'-' +
												(dt.getMonth() + 1) +
												'-' +
												dt.getDate() +
												' ' +
												dt.getHours() +
												':' +
												(dt.getMinutes() > 10 ? dt.getMinutes() : '0' + dt.getMinutes()) +
												':' +
												dt.getSeconds()
											);
										}
									}
								"></el-table-column>
							<el-table-column fixed="right" align="center" label="操作" width="160">
								<template slot-scope="scope">
									<el-button type="text" 
										@click="look(scope.row)">查看</el-button>
									<el-button type="text" 
										@click="edit(scope.row)">编辑</el-button>
									<el-button type="text" 
										@click="remove(scope.row)">删除</el-button>
								</template>
							</el-table-column>
							<template slot="empty">
								<el-empty :image-size="100" description="暂无数据"></el-empty>
							</template>
						</el-table>
					</div>
				</div>
				<div class="pagination">
					<el-pagination style="float: right" @current-change="handleCurrentChange"
						:current-page.sync="searchParm.page" :page-size="searchParm.pageSize"
						layout="total, prev, pager, next,jumper" :total="tableData.total"></el-pagination>
				</div>
			</div>
		</div>

		<edit :isShow.sync="showEditDialog" :dialogObj="editObj"></edit>
		<look :isShow.sync="showLookDialog" :dialogObj="lookObj"></look>
		<upload :isShow.sync="showUploadDialog"></upload>
	</div>
</template>
<script>
	import smCard from '@c/smCard.vue';
	import edit from './edit.vue';
	import look from './look.vue';
	import upload from './upload.vue';
	import caches from '@cache/cache.js';
	export default {
		components: {
			smCard,
			edit,
			look,
			upload
		},
		data() {
			return {
				tableHeight: 0,
				tableData: {
					records: []
				},
				searchParm: {
					pageSize: 20,
					page: 1
				},
				treeData: [],
				showEditDialog: false,
				showLookDialog: false,
				showUploadDialog: false,
				editObj: {},
				lookObj: {},
				knowledgeTypeData: [],
				rolePermissions: false, //判断当前角色是否企业角色

				isLoading: false
			};
		},
		created() {
			this.getKnowledgeTypeData();
			this.getmeasList();
		},
		methods: {
			//自适应表格高度
			calHeight() {
				this.$nextTick(() => {
					const rect = this.$refs.tableContainer.getBoundingClientRect();
					this.tableHeight = rect.height;
				});
			},
			//获取企业列表
			getmeasList() {
				let entId = caches.user.get().entId
				this.$get('/backend-api/web/monitor/tree?ent_id=' + entId).then(res => {
					if (res.code != 1000) {
						this.$message.error(res.msg);
						return;
					}
					this.treeData = res.data;
				});
			},
			//清空搜索
			clearSearch() {
				this.searchParm = {
					pageSize: 20,
					page: 1
				};
				this.list();
			},
			//知识类型
			getKnowledgeTypeData() {
				this.knowledgeTypeData = [{
						id: '0',
						name: '设备图纸'
					},
					{
						id: '1',
						name: '维修手册'
					}
				];
			},
			typeFormatter(row) {
				let data = parseInt(row.type);
				let typeName = '';
				switch (data) {
					case 1:
						typeName = '维修手册';
						break;

					default:
						typeName = '设备图纸';
						break;
				}

				return typeName;
			},
			//列表数据
			list() {
				//请求之前，开启loading
				this.isLoading = true;
				this.$get('/backend-api/eqp/set/unitFile/listPage', this.searchParm)
					.then(res => {
						this.isLoading = false;
						if (res.code == 1000) {
							this.tableData.records = res.data.records;
							this.tableData.total = res.data.total;
						} else {
							this.$message.error(res.msg);
						}
					})
					.catch(err => {
						//请求失败，关闭loading
						this.isLoading = false;
					});
			},
			//打开查看弹窗
			look(row) {
				// this.lookObj = row;
				// this.showLookDialog = true;
				if(row.fileUrl){
					window.open(this.photoURL+row.fileUrl)
				}
				
			},
			//打开上传弹窗
			uploadDialog() {
				this.showUploadDialog = true;
			},
			//打开编辑弹窗
			edit(row) {
				this.editObj = row;
				this.showEditDialog = true;
			},
			//分页选择页面回调
			handleCurrentChange(val) {
				this.searchParm.page = val;
				this.list();
			},
			//点击账户启用状态
			changeSwitch(val, row) {
				this.$post('/user-api/ent/user/status/update', {
					id: row.id,
					status: val
				}).then(res => {
					if (res.code == 1000) {
						this.$message.success(res.msg);
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			//删除
			remove(row) {
				this.$confirm('是否确认删除？', '', {
						dangerouslyUseHTMLString: true,
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					})
					.then(() => {
						this.$remove('/backend-api/eqp/set/unitFile/'+row.id).then(res => {
							if (res.code == 1000) {
								this.$message.success(res.msg);
								//刷新表格
								this.list();
							} else {
								this.$message.error(res.msg);
							}
						});
					})
					.catch(() => {});
			}
		},
		mounted() {
			this.list();
			this.calHeight();
			window.addEventListener('resize', this.calHeight);
		},
		beforeDestroy() {
			window.removeEventListener('resize', this.calHeight);
		}
	};
</script>
<style lang="scss" scoped>
	@import '@style/userCenter.scss';
</style>