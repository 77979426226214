<template>
	<el-dialog class="dialog" title="上传" :visible.sync="visiable">
		<div v-loading="loading" class="dlalog-body">
			<el-form ref="form" :model="formData" label-width="120px" :rules="rules">
				<el-row>
					<el-col :span="12">
						<el-form-item label="设备：" prop="unitId">
							<el-cascader v-model="formData.unitId" :options="treeData" :props="{
		  				value: 'id',
		  				label: 'label',
		  				children: 'children',
		  				emitPath: false
		  			}" @change="change"></el-cascader>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="20">
						<el-form-item label="文件名称" prop="fileName">
							<el-input v-model="formData.fileName"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="知识类型" prop="type">
							<el-select v-model="formData.type" placeholder="请选择">
								<el-option v-for="item in knowledgeTypeData" :key="item.id" :label="item.name"
									:value="item.id">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row class="images">
					<el-form-item label="附件" prop="url">
						<el-upload class="upload-demo" action="" :http-request="Upload" :before-upload="BeforeUpload"
							:on-remove="handleRemove" :file-list="fileList" :limit="1">
							<el-button size="small" type="primary"
								:disabled="formData.fileUrl?true:false">点击上传</el-button>
							<div slot="tip" class="el-upload__tip">
								doc/docx/pdf/jpg/png格式，不超过10M，最多限上传1个
							</div>
						</el-upload>
					</el-form-item>

				</el-row>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="close()">关闭</el-button>
				<el-button type="primary" @click="submit()">确定</el-button>
			</div>
		</div>
	</el-dialog>
</template>
<script>
	import {
		dialogMixin
	} from "@m/dialogMixin";
	import configApi from "@config/configApi";
	import caches from '@cache/cache.js';
	export default {
		mixins: [dialogMixin],
		data() {
			var validateAttach = (rule, value, callback) => {
				if (!this.formData.fileUrl) {
					callback(new Error('附件不能为空'))
				} else {
					callback()
				}
			};
			return {
				title: "",
				loading: false,
				treeData: [],
				formData: {
					parentId: ''
				},
				newFile: new FormData(),
				fileList: [],
				knowledgeTypeData: [],
				rules: {
					unitId: {
						required: true,
						message: "请选择设备",
						trigger: "blur",
					},
					fileName: {
						required: true,
						message: "请填写文件名称",
						trigger: "blur",
					},
					url: {
						validator: validateAttach,
						trigger: "blur",
					},
					type: {

						required: true,
						message: "请选择知识类型",
						trigger: "blur",
					}
				},
			};
		},
		props: {},
		watch: {},
		methods: {
			findDataById(data, id) {
				const found = data.find(item => item.id === id);
				if (found) return found;

				for (let item of data) {
					if (item.children && item.children.length > 0) {
						const result = this.findDataById(item.children, id);
						if (result) return result;
					}
				}

				return null;
			},
			change(e) {
				// 通过id查找数据
				let result = this.findDataById(this.treeData, e);

				if (result.type != 100) {
					this.$message({
						showClose: true,
						message: '当前选择不是设备类型，请重新选择',
						type: 'warning'
					});
					this.formData.unitId = '';
					return
				}

				this.formData.parentId = result.parent_id;

			},
			//打开弹窗
			open() {
				this.formData = {};
				this.fileList = [];
				this.$nextTick(() => {
					this.$refs.form.clearValidate();
				})
				this.getDepartments();
				this.getmeasList();
			},
			//附件删除
			handleRemove(file, fileList) {
				this.fileList = fileList;
				this.formData.fileUrl = "";
			},
			getmeasList() {
				let entId = caches.user.get().entId
				this.$get('/backend-api/web/monitor/tree?ent_id=' + entId).then(res => {
					if (res.code != 1000) {
						this.$message.error(res.msg);
						return;
					}
					this.treeData = res.data;
				});
			},

			//获取知识类型
			getDepartments() {
				this.knowledgeTypeData = [{
						id: 0,
						name: "设备图纸",
					},
					{
						id: 1,
						name: "维修手册",
					},

				];
			},
			//上传前校验
			BeforeUpload(file) {
				const isLt2M = file.size / 1024 / 1024 < 10;
				const fileType = file.name.substring(file.name.lastIndexOf("."));
				if (fileType != ".jpg" && fileType != ".png" && fileType != ".pdf" && fileType != ".dox" && fileType !=
					".docx") {
					this.$message.error("上传文件只能是 doc/docx/pdf/jpg/png 格式!");
				} else if (!isLt2M) {
					this.$message.error("上传文件大小不能超过 10MB!");
				} else {
					this.currentFileName = file.name;
					if (file) {
						if (this.newFile.has("file")) {
							this.newFile.delete("file");
						}
						this.newFile.append("file", file);
						return true;
					} else {
						return false;
					}
				}
				return false;
			},
			//手动上传
			Upload(param) {

				this.loading = true;
				this.$uploadWithName(this.newFile)
					.then((res) => {
						if (res.code == 1000) {
							this.formData.fileUrl = res.data;
							this.loading = false;
							this.$message.success(res.msg);
						} else {
							this.loading = false;
							this.$message.error(res.msg);
						}
					})
					.catch((error) => {
						this.$message.error(error);
					});

			},
			//提交表单
			submit() {
				if (this.formData.fileUrl) {
					this.$refs["form"].clearValidate(["fileUrl"]);
				}
				this.$refs["form"].validate((valid) => {
					if (valid) {
						this.loading = true;
						this.$postData("/backend-api/eqp/set/unitFile", this.formData).then(
							(res) => {
								if (res.code == 1000) {
									this.loading = false;
									this.$message.success(res.msg);
									this.$parent.list();
									this.close();
								} else {
									this.loading = false;
									this.$message.error(res.msg);
								}
							}
						);
					}
				});
			},
			//关闭弹窗
			close() {
				this.visiable = false;
			},
		},
	};
</script>
<style lang='scss' scoped>
	.dialog {
		::v-deep.cascader {
			display: block;
		}

		::v-deep.el-image {
			width: 80px;
			height: 80px;
			margin-right: 20px;
		}

		.images {
			margin: 10px 25px;
		}
	}

	.avatar-uploader {
		display: inline-block;
		margin-right: 20px;

		::v-deep .el-upload {
			border: 1px dashed #d9d9d9;
			border-radius: 6px;
			cursor: pointer;
			position: relative;
			overflow: hidden;
			width: 80px;
			height: 80px;
		}

		::v-deep .el-upload:hover {
			border-color: #409eff;
		}
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 80px;
		height: 80px;
		line-height: 80px;
		text-align: center;
	}
</style>